import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-accessories',
  templateUrl: './accessories.component.html',
  styleUrls: ['./accessories.component.scss'],
})
export class AccessoriesComponent implements OnInit {
  @ViewChild('sectionOneVid') sectionOneVid: ElementRef;
  @ViewChild('sectionTwoVid') sectionTwoVid: ElementRef;

  vid1Controls: boolean = false;
  vid2Controls: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  sectionOnePlay() {
    this.sectionOneVid.nativeElement.play();
    this.vid1Controls = true;
  }

  sectionOnePause() {
    this.sectionOneVid.nativeElement.pause();
    this.vid1Controls = false;
  }

  sectionTwoPlay() {
    this.sectionTwoVid.nativeElement.play();
    this.vid2Controls = true;
  }

  sectionTwoPause() {
    this.sectionTwoVid.nativeElement.pause();
    this.vid2Controls = false;
  }
}
