<div class="form__wrapper">
  <div class="feedback__form">
    <div class="feedback__form--header">
      <img src="./assets/images/logo_whitebg.png" alt="" class="form__logo" />
    </div>
    <div *ngIf="!isAnswerSubmitted" class="feedback__form__body pr-5 pl-5 pb-5">
      <p class="form__header--1 text-center">Submit Your Rating</p>
      <p class="form__header--2">
        Let us know your satisfaction with the City Velo.
      </p>
      <div class="custom__slider">
        <i class="far fa-thumbs-up fa-lg thumbs--down mr-2"></i>
        <ngx-slider [(value)]="rating" [options]="options"></ngx-slider>
        <i class="far fa-thumbs-up fa-lg ml-2"></i>
      </div>

      <p class="form__header--2">Tell us the reason for your rating.</p>
      <textarea
        class="form-control"
        rows="3"
        placeholder="Enter your response here."
      ></textarea>
      <div class="mt-3">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="submitRating()"
        >
          Submit
        </button>
      </div>
    </div>
    <div *ngIf="isAnswerSubmitted" class="feedback__form__body p-5 mt-4">
      <p
        class="form__header--1 text-center"
        style="font-size: 1.8em !important"
      >
        Thank you! Your feedback helps us improve!
      </p>
    </div>
  </div>
</div>
