import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SurveyApiService } from '../core/survey-api.service';

@Component({
  selector: 'app-all-apparel',
  templateUrl: './all-apparel.component.html',
  styleUrls: ['./all-apparel.component.scss'],
})
export class AllApparelComponent implements OnInit, OnDestroy {
  @ViewChild('sectionOneVid') sectionOneVid: ElementRef;
  @ViewChild('sectionTwoVid') sectionTwoVid: ElementRef;

  vid1Controls: boolean = false;
  vid2Controls: boolean = false;

  subscriptions$: Subscription[] = [];

  sessionGUID: string;
  headerCookie: string;
  questionMetaData: any;

  textAnswer = new FormControl('');

  isLoading: boolean = true;

  apiToken: string;

  constructor(private SurveyAPIService: SurveyApiService) {}

  ngOnInit(): void {
    this.subscriptions$.push(
      this.SurveyAPIService.newApiToken.subscribe((response: any) => {
        this.apiToken = response;
        this.getFirstQuestion();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions$.forEach((s) => s.unsubscribe());
  }

  sectionOnePlay() {
    this.sectionOneVid.nativeElement.play();
    this.vid1Controls = true;
  }

  sectionOnePause() {
    this.sectionOneVid.nativeElement.pause();
    this.vid1Controls = false;
  }

  sectionTwoPlay() {
    this.sectionTwoVid.nativeElement.play();
    this.vid2Controls = true;
  }

  sectionTwoPause() {
    this.sectionTwoVid.nativeElement.pause();
    this.vid2Controls = false;
  }

  submitAnswer(answer) {
    const response = [
      {
        QuestionFullName: this.questionMetaData.QuestionInfo.QuestionFullName,
        AnswerInputs: [
          {
            Answer: answer,
            OtherData: '',
          },
        ],
      },
    ];
    this.getNextQuestion(response);
  }

  private getFirstQuestion() {
    this.subscriptions$.push(
      this.SurveyAPIService.getFirstQuestion(2, this.apiToken).subscribe(
        (response: any) => {
          this.sessionGUID = response.SessionGuid;
          this.headerCookie = JSON.stringify(response.header[1]);
          this.questionMetaData = response.QuestionMetaData;
          this.isLoading = false;
        }
      )
    );
  }

  private getNextQuestion(response) {
    this.isLoading = true;
    this.subscriptions$.push(
      this.SurveyAPIService.getNextQuestion(
        this.sessionGUID,
        this.headerCookie,
        response,
        this.apiToken
      ).subscribe((response: any) => {
        this.questionMetaData = response.QuestionMetaData;
        this.isLoading = false;
      })
    );
  }
}
