<div class="footer row no-gutters">
  <div class="footer-links">
    <div class="row no-gutters">
      <p>BIKES</p>
    </div>
    <div class="row no-gutters">
      <p>Mountain Bikes</p>
    </div>
    <div class="row no-gutters">
      <p>Road Bikes</p>
    </div>
    <div class="row no-gutters">
      <p>Cross-Country</p>
    </div>
    <div class="row no-gutters">
      <p>Electric Bikes</p>
    </div>
  </div>

  <div class="footer-links">
    <div class="row no-gutters">
      <p>EQUIPMENT</p>
    </div>
    <div class="row no-gutters">
      <p>Tires</p>
    </div>
    <div class="row no-gutters">
      <p>Wheels</p>
    </div>
    <div class="row no-gutters">
      <p>Lights</p>
    </div>
    <div class="row no-gutters">
      <p>Breaks</p>
    </div>
    <div class="row no-gutters">
      <p>Pedals</p>
    </div>
    <div class="row no-gutters">
      <p>Accessories</p>
    </div>
  </div>

  <div class="footer-links">
    <div class="row no-gutters">
      <p>APPAREL</p>
    </div>
    <div class="row no-gutters">
      <p>Helmets</p>
    </div>
    <div class="row no-gutters">
      <p>Shoes</p>
    </div>
    <div class="row no-gutters">
      <p>Jerseys</p>
    </div>
    <div class="row no-gutters">
      <p>Shorts</p>
    </div>
    <div class="row no-gutters">
      <p>Gloves</p>
    </div>
    <div class="row no-gutters">
      <p>Jackets</p>
    </div>
    <div class="row no-gutters">
      <p>Pants</p>
    </div>
    <div class="row no-gutters">
      <p>Shirts</p>
    </div>
  </div>

  <div class="footer-links">
    <div class="row no-gutters">
      <p>LOCATIONS</p>
    </div>
    <div class="row no-gutters">
      <p>High Bridge, NY</p>
    </div>
    <div class="row no-gutters">
      <p>Rock Creek, WA</p>
    </div>
    <div class="row no-gutters">
      <p>Mammoth Cave, KY</p>
    </div>
    <div class="row no-gutters">
      <p>Discovery Trail, WA</p>
    </div>
    <div class="row no-gutters">
      <p>Bear Creek, OR</p>
    </div>
    <div class="row no-gutters">
      <p>Mid-Moutain Trail, UT</p>
    </div>
  </div>
</div>
