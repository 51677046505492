<div class="page-wrapper">
  <div class="section">
    <div class="section-description">
      <p class="desc-title mb-3">Cycling Jerseys</p>
      <p class="desc-subtext mb-3">
        <strong>Product Description:</strong> <br />With a comfortable, relaxed
        fit and soft fabric, the Mammoth Cycling Jersey will be your go-to top
        when it's time to get in the saddle.
      </p>
      <p class="desc-subtext mt-3"><strong>Additional Features:</strong></p>
      <ul class="desc-subtext mb-3">
        <li>
          <span
            >Made with a soft, micro-perforated fabric that is highly breathable
            to keep you cool and dry.</span
          >
        </li>
        <li>
          <span>Three rear bellowed pockets for on-the-road essentials.</span>
        </li>
        <li>
          <span
            >Flattering side seams and an updated collar design add feminine
            detail.</span
          >
        </li>
      </ul>
    </div>
    <div class="section-media">
      <video
        #sectionOneVid
        muted
        (mouseenter)="sectionOnePlay()"
        (focus)="sectionOnePlay()"
        (mouseout)="sectionOnePause()"
        (blur)="sectionOnePause()"
        src="assets/videos/vid5.mp4"
        poster="assets/images/vid5.png"
        [controls]="vid1Controls"
      ></video>
    </div>
  </div>
  <div class="section">
    <div class="section-media">
      <video
        #sectionTwoVid
        muted
        (mouseenter)="sectionTwoPlay()"
        (focus)="sectionTwoPlay()"
        (mouseout)="sectionTwoPause()"
        (blur)="sectionTwoPause()"
        src="assets/videos/vid6.mp4"
        poster="assets/images/vid6.png"
        [controls]="vid2Controls"
      ></video>
    </div>
    <div class="section-description">
      <p class="desc-title mb-3">Cycling Shoes</p>
      <p class="desc-subtext mb-3">
        <strong>Product Description:</strong> <br />Road Cycling Shoes have
        exactly what you need to focus on the road in front of you. The
        fatigue-resistant, stiff plate and highly breathable upper keep you
        clipped in and putting miles behind you.
      </p>
      <p class="desc-subtext mt-3"><strong>Product Details:</strong></p>
      <ul class="desc-subtext mb-3">
        <li><span>Bonded, seamless upper made with breathable mesh.</span></li>
        <li>
          <span
            >1:1 anatomical closure follows the foot's shape to help eliminate
            hot spots.</span
          >
        </li>
        <li><span>Contoured heel cup for maximum comfort.</span></li>
        <li><span>Plush collar and breathable fabric lining.</span></li>
      </ul>
    </div>
  </div>
  <div class="survey">
    <div *ngIf="questionMetaData" class="survey__wrapper">
      <div class="survey__content" [ngClass]="{ disabled: isLoading }">
        <div *ngIf="!questionMetaData.IsEndOfSurvey" class="question__label">
          <p>{{ questionMetaData.QuestionInfo.Label }}</p>
        </div>
        <div *ngIf="questionMetaData.IsEndOfSurvey" class="question__label">
          <p class="mb-2 cv-green">Thank you for your feedback!</p>
          <div class="d-flex flex-column align-items-center">
            <img
              src="./assets/images/cxo_avatar.jpg"
              alt=""
              class="cxo__avatar mb-2 cv-green"
            />
            <p class="cv-green">Jeff Brown, CXO</p>
          </div>
        </div>
        <div
          *ngIf="questionMetaData.QuestionInfo.QuestionName === 'Q1'"
          class="single__punch__options"
        >
          <star-rating
            [starType]="'svg'"
            [size]="'large'"
            [staticColor]="'positive'"
            (ratingChange)="submitAnswer($event.rating)"
            style="z-index: 1"
          ></star-rating>
        </div>
        <div
          *ngIf="questionMetaData.QuestionInfo.QuestionName === 'Q2'"
          class="single__punch__options"
        >
          <div class="d-flex pl-2">
            <label class="custom-radio-container"
              >Yes
              <input type="radio" name="radio" (click)="submitAnswer('Yes')" />
              <span class="custom-radio"></span>
            </label>
            <label class="ml-3 custom-radio-container"
              >No
              <input type="radio" name="radio" (click)="submitAnswer('No')" />
              <span class="custom-radio"></span>
            </label>
          </div>
        </div>
        <div
          *ngIf="questionMetaData.QuestionInfo.AnswerDataType === 'Text'"
          class="text__options"
        >
          <textarea
            name="textAnswer"
            class="form-control"
            rows="3"
            placeholder="Enter your response..."
            [formControl]="textAnswer"
          ></textarea>
          <button
            type="button"
            class="btn btn-secondary mt-3"
            [disabled]="!textAnswer.value"
            (click)="submitAnswer(textAnswer.value)"
          >
            Submit
          </button>
        </div>
      </div>
      <div *ngIf="isLoading" class="loader__wrapper">
        <div class="loader"></div>
      </div>
    </div>
  </div>
</div>
