import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class SurveyApiService {
  apiToken$ = new BehaviorSubject(null);
  newApiToken = this.apiToken$.asObservable();

  constructor(private httpClient: HttpClient) {}

  loginToAPI() {
    return this.httpClient.get(environment.surveyAPI.loginURL);
  }

  getFirstQuestion(surveyId, token) {
    const formData: FormData = new FormData();
    formData.append('cityveloToken', token);
    formData.append('surveyId', surveyId);

    return this.httpClient.post(
      environment.surveyAPI.firstQuestionURL,
      formData
    );
  }

  getNextQuestion(sessionGUID, headerCookie, response, token) {
    const body = {
      cityveloToken: token,
      cityveloSessionGUID: sessionGUID,
      cityveloCookie: headerCookie,
      QuestionAndSubQuestionResponses: response,
    };

    return this.httpClient.post(
      environment.surveyAPI.nextQuestionURL,
      body,
      HTTP_OPTIONS
    );
  }
}
