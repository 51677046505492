<div class="page-wrapper">
  <div class="section">
    <div class="section-description">
      <p class="desc-title mb-3">15 Ounce Bottle</p>
      <p class="desc-subtext mb-3">
        <strong>Product Description:</strong> <br />These 15 ounce bottles
        feature a double wall vacuum construction to help preserve the
        temperature of your drinks. With a screw on and spill resistant cap,
        this bottle holds up to 15 ounces and are made with durable stainless
        steel. Select from silver, black, or red.
      </p>
      <p class="desc-subtext mt-3"><strong>Product Details:</strong></p>
      <ul class="desc-subtext mb-3">
        <li>Double Wall Construction for Insulation</li>
        <li>Screw On, Spill-Resistant Lid</li>
        <li>BPA Free</li>
        <li>15 oz.</li>
        <li>Keep drinks hot/cold for up to 4 hours </li>
      </ul>
    </div>
    <div class="section-media">
      <video
        #sectionOneVid
        muted
        (mouseenter)="sectionOnePlay()"
        (focus)="sectionOnePlay()"
        (mouseout)="sectionOnePause()"
        (blur)="sectionOnePause()"
        src="assets/videos/vid4.mp4"
        poster="assets/images/vid4.png"
        [controls]="vid1Controls"
      ></video>
    </div>
  </div>
  <div class="section">
    <div class="section-media">
      <video
        #sectionTwoVid
        muted
        (mouseenter)="sectionTwoPlay()"
        (focus)="sectionTwoPlay()"
        (mouseout)="sectionTwoPause()"
        (blur)="sectionTwoPause()"
        src="assets/videos/vid1.mp4"
        poster="assets/images/vid1.png"
        [controls]="vid2Controls"
      ></video>
    </div>
    <div class="section-description">
      <p class="desc-title mb-3">Climber Backpack</p>
      <p class="desc-subtext mb-3">
        <strong>Product Description:</strong> <br />The Climber Backpack by
        CityVelo is made of sturdy 600D polyester with rip-stop accents. Large
        main zippered compartment and a front zippered pocket with earbud port
        leading into pocket.
      </p>
      <p class="desc-subtext mt-3"><strong>Product Details:</strong></p>
      <ul class="desc-subtext mb-3">
        <li>2 side mesh pockets</li>
        <li>Elastic rope on the front panel</li>
        <li>Padded back panel</li>
        <li>Padded mesh adjustable shoulder straps.</li>
        <li>Black hanging handle.</li>
        <li>Dimensions 17 1/2" H x 12" W x 5 5/8" D</li>
      </ul>
    </div>
  </div>
</div>
