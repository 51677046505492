import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare var $;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  navigateToMountainBikes(element) {
    this.hideDropdown(element);
    this.router.navigateByUrl('/mountain-bikes');
  }

  navigateToAccessories(element) {
    this.hideDropdown(element);
    this.router.navigateByUrl('/accessories');
  }

  navigateToAllApparel(element) {
    this.hideDropdown(element);
    this.router.navigateByUrl('/all-apparel');
  }

  backToHome() {
    this.router.navigateByUrl('/');
  }

  showDropdown(element) {
    $(element).css('display', 'block');
    $(element).css('visibility', 'visible');
  }

  hideDropdown(element) {
    $(element).css('display', 'none');
    $(element).css('visibility', 'hidden');
  }
}
