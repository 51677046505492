import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SurveyApiService } from './survey-api.service';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
})
export class CoreComponent implements OnInit, OnDestroy {
  subscription$: Subscription;

  constructor(private SurveyApiService: SurveyApiService) {}

  ngOnInit(): void {
    this.subscription$ = this.SurveyApiService.loginToAPI().subscribe(
      (response: any) => {
        this.SurveyApiService.apiToken$.next(response.token);
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
