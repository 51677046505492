import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'messagesFilter',
  pure: false,
})
export class MessagesFilter implements PipeTransform {
  transform(items: any[], filter: any): any {
    if (!items || !filter) {
      return items;
    }
    return items.filter((item) => {
      if (item.name === 'admin')
        return item.message.recipient === filter.recipient;
      else return item.name === filter.recipient;
    });
  }
}
