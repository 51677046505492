import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccessoriesComponent } from './accessories/accessories.component';
import { AllApparelComponent } from './all-apparel/all-apparel.component';
import { ChatAdminComponent } from './chat-admin/chat-admin.component';
import { CoreComponent } from './core/core.component';
import { HomeComponent } from './home/home.component';
import { MountainBikesComponent } from './mountain-bikes/mountain-bikes.component';
import { SubmitRatingComponent } from './submit-rating/submit-rating.component';

const routes: Routes = [
  {
    path: '',
    component: CoreComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'mountain-bikes',
        component: MountainBikesComponent,
      },
      {
        path: 'accessories',
        component: AccessoriesComponent,
      },
      {
        path: 'all-apparel',
        component: AllApparelComponent,
      },
    ],
  },
  {
    path: 'chat-admin',
    component: ChatAdminComponent,
  },
  {
    path: 'submit-rating',
    component: SubmitRatingComponent,
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
