<div class="header-sm">
  <a>
    <i class="fas fa-bars fa-2x"></i>
  </a>
  <img src="./assets/images/logo_whitebg.png" alt="" (click)="backToHome()" />
  <span style="flex: 1"></span>
  <a>
    <i class="fas fa-user"></i>
  </a>
  <a>
    <i class="fas fa-shopping-cart"></i>
  </a>
  <a class="mr-3">
    <i class="fas fa-search"></i>
  </a>
</div>

<div class="header">
  <div class="header-links">
    <span style="flex: 1"></span>
    <a class="mr-4">
      <i class="fas fa-user fa-lg"></i>
    </a>
    <a class="mr-4">
      <i class="fas fa-shopping-cart fa-lg"></i>
    </a>
    <a class="mr-4">
      <i class="fas fa-search fa-lg"></i>
    </a>
  </div>
  <div class="header-main">
    <img
      class="ml-5"
      src="./assets/images/logo_whitebg.png"
      alt=""
      style="cursor: pointer"
      (click)="backToHome()"
    />
    <div class="w-100 ml-5">
      <ul class="row no-gutters w-100 nav-wrapper">
        <li
          class="nav-item col"
          (mouseenter)="showDropdown(bikesDropdown)"
          (mouseleave)="hideDropdown(bikesDropdown)"
        >
          <a aria-haspopup="true">BIKES</a>
          <ul #bikesDropdown class="dropdown" aria-label="submenu">
            <li (click)="navigateToMountainBikes(bikesDropdown)">
              <a>Mountain Bikes</a>
            </li>
            <li><a>Road Bikes</a></li>
            <li><a>Cross-Country</a></li>
            <li><a>Electric Bikes</a></li>
          </ul>
        </li>
        <li
          class="nav-item col"
          (mouseenter)="showDropdown(equipmentDropdown)"
          (mouseleave)="hideDropdown(equipmentDropdown)"
        >
          <a aria-haspopup="true">EQUIPMENT</a>
          <ul #equipmentDropdown class="dropdown" aria-label="submenu">
            <li><a>Tires</a></li>
            <li><a>Wheels</a></li>
            <li><a>Lights</a></li>
            <li><a>Brakes</a></li>
            <li><a>Pedals</a></li>
            <li (click)="navigateToAccessories(equipmentDropdown)">
              <a>Accessories</a>
            </li>
          </ul>
        </li>
        <li
          class="nav-item col"
          (mouseenter)="showDropdown(allApparelDropdown)"
          (mouseleave)="hideDropdown(allApparelDropdown)"
        >
          <a aria-haspopup="true">APPAREL</a>
          <ul #allApparelDropdown class="dropdown" aria-label="submenu">
            <li><a>Helmets</a></li>
            <li><a>Shoes</a></li>
            <li><a>Jerseys</a></li>
            <li><a>Shorts</a></li>
            <li><a>Gloves</a></li>
            <li><a>Jackets</a></li>
            <li><a>Pants</a></li>
            <li><a>Shirts</a></li>
            <li (click)="navigateToAllApparel(allApparelDropdown)">
              <a>All Apparel</a>
            </li>
          </ul>
        </li>
        <li class="nav-item col">
          <a aria-haspopup="true">LOCATIONS</a>
          <ul class="dropdown" aria-label="submenu">
            <li><a>High Bridge, NY</a></li>
            <li><a>Rock Creek, WA</a></li>
            <li><a>Mammoth Cave, KY</a></li>
            <li><a>Discovery Trail, WA</a></li>
            <li><a>Bear Creek, OR</a></li>
            <li><a>Mid-Mountain Trail, UT</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
