import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { CoreComponent } from './core/core.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { MountainBikesComponent } from './mountain-bikes/mountain-bikes.component';
import { AccessoriesComponent } from './accessories/accessories.component';
import { AllApparelComponent } from './all-apparel/all-apparel.component';
import { ChatClientComponent } from './chat-client/chat-client.component';

import { StarRatingModule } from 'angular-star-rating';
import { ChatAdminComponent } from './chat-admin/chat-admin.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MessagesFilter } from './chat-admin/messages-filter.pipe';
import { SubmitRatingComponent } from './submit-rating/submit-rating.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    CoreComponent,
    HomeComponent,
    FooterComponent,
    MountainBikesComponent,
    AccessoriesComponent,
    AllApparelComponent,
    ChatClientComponent,
    ChatAdminComponent,
    MessagesFilter,
    SubmitRatingComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    StarRatingModule.forRoot(),
    NgxSliderModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
