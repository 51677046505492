<div class="page-wrapper">
  <div class="section">
    <div class="section-description">
      <p class="desc-title mb-3">The Kennedy</p>
      <p class="desc-subtext mb-3">
        Whether you’re chasin’ interns, starlets, or the American Dream,
        Kennedy’s matte black murdered-out look will have you asking not what
        your bike can do for you, but what you can do for your bike.  
      </p>
      <p class="desc-subtext mb-3">
        This series is the perfect mountain bike to take your riding to the next
        level. Featuring a double-butted <strong>4130 chromoly</strong> frame,
        the Kennedy Series was bred to be lighter, stiffer, and faster. It comes
        street-ready with wrapped drop bars, our 700C, 30mm wheelset featuring
        sealed bearing hubs and a machined braking surface, and
        includes <strong>Thickslick</strong> 700x28c tires standard.
      </p>
    </div>
    <div class="section-media">
      <video
        #sectionOneVid
        muted
        (mouseenter)="sectionOnePlay()"
        (focus)="sectionOnePlay()"
        (mouseout)="sectionOnePause()"
        (blur)="sectionOnePause()"
        src="assets/videos/vid1.mp4"
        poster="assets/images/vid1.png"
        [controls]="vid1Controls"
      ></video>
    </div>
  </div>
  <div class="section">
    <div class="section-media">
      <video
        #sectionTwoVid
        muted
        (mouseenter)="sectionTwoPlay()"
        (focus)="sectionTwoPlay()"
        (mouseout)="sectionTwoPause()"
        (blur)="sectionTwoPause()"
        src="assets/videos/vid2.mp4"
        poster="assets/images/vid2.png"
        [controls]="vid2Controls"
      ></video>
    </div>
    <div class="section-description">
      <p class="desc-title mb-3">Artist Series</p>
      <p class="desc-subtext mb-3">
        Every bike is a work of art, but sometimes you have to make it official.
        We love the creativity, vision, and personality that bikes bring out in
        people and we love artists for putting so much cool stuff into the
        universe. That’s why we never miss a chance to collaborate with today’s
        most inspiring creators.
      </p>
      <p class="desc-subtext mb-3">
        Bringing a fresh look to our designs, revealing beauty in utility, and
        turning transportation into something more meaningful, the Artist
        Spotlight Series is our tribute to the creative energy that brings
        magnificence to the mundane and makes our world such a wonderful place
        to ride.
      </p>
    </div>
  </div>
  <div class="section">
    <div class="section-description">
      <p class="desc-title mb-3">The X-Ray</p>
      <p class="desc-subtext mb-3">
        With it's smooth cream frame and mint green wheels, the X-Ray is a ride
        that looks as sweet as it would taste (it actually tastes a lot like
        metal) and rumor has it that the X-Ray was the inspiration behind the
        first bottles of creme de menthe. Whatever you believe, this is one
        smooth bike that will leave you looking and feeling fresh.
      </p>
      <p class="desc-subtext-italic mb-3 mt-3">
        <i
          >*43cm will come with a rear brake, 650c wheels, and 650x23c tires.</i
        >
      </p>
    </div>
    <div class="section-media">
      <video
        #sectionThreeVid
        muted
        (mouseenter)="sectionThreePlay()"
        (focus)="sectionThreePlay()"
        (mouseout)="sectionThreePause()"
        (blur)="sectionThreePause()"
        src="assets/videos/vid3.mp4"
        poster="assets/images/vid3.png"
        [controls]="vid3Controls"
      ></video>
    </div>
  </div>

  <div *ngIf="questionMetaData" class="survey__wrapper">
    <div class="survey__content" [ngClass]="{ disabled: isLoading }">
      <div *ngIf="!questionMetaData.IsEndOfSurvey" class="question__label">
        <p>{{ questionMetaData.QuestionInfo.Label }}</p>
      </div>
      <div *ngIf="questionMetaData.IsEndOfSurvey" class="question__label">
        <p
          *ngIf="questionMetaData.BannerLabels[0].Text.indexOf('Thank') !== -1"
        >
          {{ questionMetaData.BannerLabels[0].Text }}
        </p>
        <p
          *ngIf="questionMetaData.BannerLabels[0].Text.indexOf('Thank') === -1"
        >
          For City Velo Accessories,
          <a href="/accessories">click here</a>.
        </p>
        <button
          type="button"
          class="btn btn-secondary mt-3"
          (click)="questionMetaData = null"
        >
          Close
        </button>
      </div>
      <div
        *ngIf="
          questionMetaData.QuestionInfo.AnswerDataType ===
          'SinglePunchCategorical'
        "
        class="single__punch__options"
      >
        <button
          *ngFor="let option of questionMetaData.QuestionInfo.PossibleAnswers"
          type="button"
          class="btn btn-secondary"
          (click)="submitAnswer(option.Name)"
        >
          {{ option.Label }}
        </button>
      </div>
      <div
        *ngIf="questionMetaData.QuestionInfo.AnswerDataType === 'Text'"
        class="text__options"
      >
        <textarea
          name="textAnswer"
          class="form-control"
          rows="3"
          placeholder="Enter your answer..."
          [formControl]="textAnswer"
        ></textarea>
        <button
          type="button"
          class="btn btn-secondary mt-3"
          [disabled]="textAnswer.value === ''"
          (click)="submitAnswer(textAnswer.value)"
        >
          Submit
        </button>
      </div>
    </div>
    <div *ngIf="isLoading" class="loader__wrapper">
      <div class="loader"></div>
    </div>
  </div>
</div>
