<div class="section-one">
  <div class="home-img"></div>
  <!-- <img src="./assets/images/home_img.jpg" alt="" /> -->
</div>

<div class="section-two">
  <p class="header-one">Find Your Bike</p>
  <div class="bikes-wrapper">
    <div class="bike text-center mb-3">
      <img src="./assets/images/home_bike_1.jpg" alt="" />
      <button class="btn btn-lg btn-secondary">View</button>
    </div>
    <div class="bike text-center mb-3">
      <img src="./assets/images/home_bike_2.jpg" alt="" />
      <button class="btn btn-lg btn-secondary">View</button>
    </div>
    <div class="bike text-center mb-3">
      <img src="./assets/images/home_bike_3.jpg" alt="" />
      <button class="btn btn-lg btn-secondary">View</button>
    </div>
  </div>
  <p class="header-two">High quality fixed gear bikes</p>
  <div class="text-center">
    <img class="chain-logo" src="./assets/images/chain.png" alt="" />
  </div>
  <p class="long-text">
    Our products are inspired by the people and world around us. Beautiful, high
    quality goods that are designed especially for you. Discover our story and
    meet the people that make our brand what it is today.
  </p>
</div>
